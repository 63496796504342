import { render, staticRenderFns } from "./FromsApp.vue?vue&type=template&id=231b3a91&scoped=true"
import script from "./FromsApp.vue?vue&type=script&lang=js"
export * from "./FromsApp.vue?vue&type=script&lang=js"
import style0 from "./FromsApp.vue?vue&type=style&index=0&id=231b3a91&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231b3a91",
  null
  
)

export default component.exports